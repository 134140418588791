<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-06-15 20:16:07
-->
<template>
  <div class="topbanner">
    <el-carousel class="width-s" v-if="bannerList.length > 0">
      <el-carousel-item v-for="item in bannerList" :key="item.pkId">
        <img mode="widthFix" class="bannerimg" :src="item.picture" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import * as api from "@/api/index.js";

export default {
  name: "topBanner",
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      api.getIndexBanner().then((res) => {
        console.log("🌈77777777", res);
        this.bannerList = res.rows;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-carousel__container{
//   height: 550px;
// }
.width-s{
  //padding-bottom: 56%;
  //width: 1000px;
  position: relative;
}
::v-deep .el-carousel--horizontal {
  border-radius: 8px;
}
.bannerimg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.topbanner {
  // height: 253px;
  // background: #d8d8d8;
  // border-radius: 10px 10px 10px 10px;
}
</style>